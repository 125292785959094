// eslint-disable-next-line import/no-cycle
import apiClient from '../../api';
// eslint-disable-next-line import/no-cycle
import router from '../../router';
// eslint-disable-next-line import/no-cycle
import store, { resetStores } from '..';
import initialState from '../initialState';
import { isNum, isObj, isArr, isStr } from '../../utils';
// eslint-disable-next-line import/no-cycle
import isAuthorized from '../../utils/permissions';

const authApi = apiClient.merchy.auth;
const usersApi = apiClient.merchy.users;
const businessUsersApi = apiClient.merchy.businessUsers;

/**
 * @description Is valid
 * @param auth
 * @returns {boolean}
 */
const isValid = (auth) =>
  isObj(auth) &&
  isObj(auth.token) &&
  isObj(auth.role) &&
  isObj(auth.user) &&
  isArr(auth.permissions) &&
  isObj(auth.redirects) &&
  isObj(auth.sourceRoute);

/**
 * @description Init state
 * @param initialState
 * @returns {*}
 */
const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial auth state');
  }

  const {
    token,
    user,
    role,
    permissions,
    redirects,
    sourceRoute,
  } = initialState;
  return {
    token,
    user,
    role,
    permissions,
    redirects,
    sourceRoute,
  };
};

/**
 * @description Getters
 * @type {*}
 */
export const getters = {
  user: ({ user }) => user,
  role: ({ role }) => role,
  token: ({ token }) => token,
  permissions: ({ permissions }) => permissions,
  redirects: ({ redirects }) => redirects,
  isAuthorized:
    ({ permissions }) =>
      (requestedPermissions) =>
        isAuthorized(permissions, requestedPermissions),
  isLogged: ({ user }) => isNum(user.id),
  sourceRoute: ({ sourceRoute }) => sourceRoute,
};

/**
 * @description Handle alerts
 * @param data
 * @param alertType
 */
const handleAlerts = (data, alertType = 'error') =>
  store.dispatch('alerts/set', {
    data,
    alertType,
  });

/**
 * @description Set ability
 * @param permissions
 */
const setAbility = (permissions) => {
  const filteredPermitions = [];

  permissions.forEach(item => {
    item.actions.forEach(action => {
      filteredPermitions.push({
        subject: item.subject,
        action: action
      });
    })
  })

  router.app.$ability.update(filteredPermitions);
};

/**
 * @description Revoke ability
 */
const revokeAbility = () => {
  router.app.$ability.update([]);
};

/**
 * @description Set redirects
 * @returns {*}
 */
const setRedirects = () => {
  if (router.app.$ability.can('view', 'thread')) {
    return '/threads';
  }
  if (router.app.$ability.can('view', 'billing')) {
    return '/billing';
  }
};

const actions = {
  executeLogin: ({ commit, state }, data) => {
    const { token, role, user, permissions } = data;
    const { name, path } = state.sourceRoute;

    setAbility(permissions);

    let allowRedirect = false;

    if (isStr(name) && isStr(path) && isObj(router.options)) {
      router.options.routes.map((route) => {
        if (route.name === name && route.meta.permissions) {
          const { subject, actions } = route.meta.permissions[0];
          const action = actions[0];

          allowRedirect = router.app.$ability.can(action, subject);
        }

        return allowRedirect;
      });
    }

    const nextRedirects = {
      ...state.redirects,
      authenticated: allowRedirect ? path : setRedirects(),
    };

    const nextAuth = {
      ...state,
      token,
      role,
      user,
      permissions,
      redirects: nextRedirects,
      sourceRoute: {},
    };

    commit('SET', nextAuth);

    // TODO uncomment if needed
    // store.dispatch('users/getStatuses');
    // store.dispatch('config/getNotificationAssignments');
    // store.dispatch('config/getNotificationAccountTypes');

    const getRoles = store.dispatch('users/getRoles').catch((err) => err);
    const getCountries = store.dispatch('users/getCountries').catch((err) => err);
    const getLanguages = store.dispatch('config/getLanguages').catch((err) => err);
    const getTimezones = store.dispatch('config/getTimezones').catch((err) => err);
    const getColors = store.dispatch('config/getColors').catch((err) => err);

    return Promise.all([getRoles, getCountries, getLanguages, getTimezones, getColors]);
  },
  login: (context, userAuth) =>
    authApi
      .login(userAuth)
      .then((res) => store.dispatch('auth/executeLogin', res.data.data)
        .then(router.replace({ name: 'index' }))),
  logIntoAccount: ({ commit, state }, user) =>
    usersApi.logIntoAccount(user).then((res) => {
      const { token, role, user, permissions } = res.data.data;
      const nextAuth = {
        ...state,
        token,
        role,
        user,
        permissions,
      };

      store.dispatch('config/reset');
      store.dispatch('users/reset');

      setAbility(permissions);
      commit('SET', nextAuth);

      store.dispatch('users/getCountries');
      return router.replace({ name: 'login' });
    }),
  getUserPermissions: ({ commit, state }, query) =>
    authApi.getUserPermissions(query).then((res) => {
      const { data } = res.data;

      const nextAuth = {
        ...state,
        permissions: data,
      };

      setAbility(data);
      commit('SET', nextAuth);

      return data;
    }),
  register: (context, user) =>
    authApi.register(user).then((res) => {
      handleAlerts(res.data, 'success');
      return router.replace({ name: 'login' });
    }),
  registerInvited: (context, user) =>
    authApi.registerInvited(user).then(() => {
      const userAuth = {
        email: user.email,
        password: user.password,
      };

      return actions.login(context, userAuth);
    }),
  sendPassResetRequest: (context, email) =>
    authApi.sendPassResetRequest(email).then((res) => {
      handleAlerts(res.data, 'success');
      return router.replace({ name: 'login' });
    }),
  resetPass: (context, { token, email, password }) =>
    authApi.resetPass(token, email, password).then((res) => {
      handleAlerts(res.data, 'success');
      return router.replace({ name: 'login' });
    }),
  setPass: (context, { token, email, password }) => {
    return authApi
      .setPass(token, email, password)
      .then((res) => store.dispatch('auth/executeLogin', res.data.data))
      .then(() => router.replace({ name: 'index' }))
  },
  confirmBusiness: (context, userDetails) =>
    authApi.confirmBusiness(userDetails).then((res) => res),
  isValidRegistrationToken: ({ commit, state }, token) =>
    authApi.isValidRegistrationToken(token).then((res) => {
      const { user, emailDomain, businesses } = res.data.data;

      const nextAuth = {
        ...state,
        user: {
          ...user,
          emailDomain,
          businesses,
        },
      };

      commit('SET', nextAuth);
      return res;
    }),
  isValidInvitationToken: ({ commit, state }, token) =>
    authApi.isValidInvitationToken(token).then((res) => {
      const { firstname, lastname, email, business } = res.data.data;
      const nextAuth = {
        ...state,
        user: {
          firstname,
          lastname,
          email,
          business,
        },
      };

      commit('SET', nextAuth);
      return res;
    }),
  isValidPassResetToken: ({ commit, state }, { token, email }) =>
    authApi.isValidPassResetToken(token, email).then((res) => {
      const { email } = res.data.data;

      const nextAuth = {
        ...state,
        user: {
          ...state.user,
          email,
        },
      };

      commit('SET', nextAuth);
      return res;
    }),
  isValidPassSetToken: ({ commit, state }, { token }) => {
    return authApi.isValidPassSetToken(token).then((res) => {
      const { email } = res.data.data;

      const nextAuth = {
        ...state,
        user: {
          ...state.user,
          email,
        },
      };

      commit('SET', nextAuth);
      return res;
    })},
  logoutAction: ({ commit }) => {
    return authApi.logout().then(() => {
      actions.resetAll({ commit });
      revokeAbility();
    })},
  logout: ({ commit }) =>
    authApi.logout().then((res) => {
      // We have another logout functionality in the logoutAction method
      handleAlerts(res.data, 'success');
      actions.resetAll({ commit });
      revokeAbility();

      return router.replace({ name: 'index' });
    }),
  refreshToken: ({ commit, state }, query) =>
    authApi.refreshToken(query).then((res) => {
      const { token } = res.data.data;

      const nextAuth = {
        ...state,
        token,
      };

      commit('SET', nextAuth);
      return res;
    }),
  redirectInvalidToken: ({ commit }) => {
    commit('SET', initialState.auth);
    handleAlerts({
      data: {
        message:
          'Your session has expired. Please log into your account again.',
      },
    });
    return router.replace({ name: 'index' });
  },
  updateRedirects: ({ commit, state }, redirects) => {
    const nextAuth = {
      ...state,
      redirects: {
        ...state.redirects,
        ...redirects,
      },
    };

    commit('SET', nextAuth);
  },
  updateLoggedUser: ({ commit, state }, query) => {
    const nextUser = {
      ...state.user,
      ...query,
    };

    const nextState = {
      ...state,
      user: nextUser,
    };

    commit('SET', nextState);

    return nextUser;
  },
  updateSourceRoute: ({ commit, state }, query) => {
    const nextAuth = {
      ...state,
      sourceRoute: query,
    };

    commit('SET', nextAuth);

    return nextAuth;
  },
  acceptTerms: ({ commit, state }) =>
    businessUsersApi.acceptTerms().then((res) => {
      const nextUser = {
        ...state,
        user: res.data.data,
      };

      commit('SET', nextUser);
      return res;
    }),
  updateEnabledCommunication: ({ commit, state }, query) =>
    businessUsersApi.updateEnabledCommunication(query).then((res) => {
      const nextUser = {
        ...state,
        user: res.data.data,
      };

      commit('SET', nextUser);
      return res;
    }),
  reset: ({ commit }) => commit('SET', initialState.auth),
  resetAll: () => resetStores(store),
  set: ({ commit }, auth) => {
    commit('SET', auth);
  },
};

const mutations = {
  SET(state, auth) {
    /* eslint-disable no-param-reassign */
    state.token = auth.token;
    state.user = auth.user;
    state.role = auth.role;
    state.permissions = auth.permissions;
    state.redirects = auth.redirects;
    state.sourceRoute = auth.sourceRoute;
  },
};

export default (initialState) => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
