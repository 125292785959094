import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { apiUrl } from './index';

export default {
  login(userAuth) {
    return axios({
      method: 'post',
      url: `${apiUrl}/auth/login`,
      data: userAuth,
    });
  },
  logout() {
    return axios({
      method: 'post',
      url: `${apiUrl}/auth/logout`,
    });
  },
  getUserPermissions(query) {
    return axios({
      method: 'get',
      url: `${apiUrl}/permissions/${query.country}`,
    });
  },
  refreshToken({ refresh_token }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/auth/refresh`,
      data: {
        refresh_token,
      },
    });
  },
  register(user) {
    return axios({
      method: 'post',
      url: `${apiUrl}/register/new/${user.token}`,
      data: {
        ...user,
      },
    });
  },
  registerInvited(user) {
    return axios({
      method: 'post',
      url: `${apiUrl}/register/invited`,
      data: user,
    });
  },
  confirmBusiness(userDetails) {
    return axios({
      method: 'post',
      url: `${apiUrl}/register/first`,
      data: userDetails,
    });
  },
  isValidRegistrationToken(token) {
    return axios({
      method: 'post',
      url: `${apiUrl}/register/second/${token}`,
      data: {
        token,
      },
    });
  },
  isValidInvitationToken(token) {
    return axios({
      method: 'get',
      url: `${apiUrl}/register/invited/${token}`,
      params: {
        token,
      },
    });
  },
  isValidPassResetToken(token, email) {
    const params = {
      token,
      email_encrypted: email,
    };

    return axios({
      method: 'get',
      url: `${apiUrl}/password/reset`,
      params,
    });
  },
  isValidPassSetToken(token) {
    const params = {
      token,
    };

    return axios({
      method: 'get',
      url: `${apiUrl}/password/request/${token}`,
      params,
    });
  },
  sendPassResetRequest(email) {
    return axios({
      method: 'post',
      url: `${apiUrl}/password/email`,
      data: {
        email,
      },
    });
  },
  resetPass(token, email, password) {
    return axios({
      method: 'post',
      url: `${apiUrl}/password/reset`,
      data: {
        token,
        email,
        password,
      },
    });
  },
  setPass(token, email, password) {
    return axios({
      method: 'post',
      url: `${apiUrl}/password/set/${token}`,
      data: {
        token,
        email,
        password,
      },
    });
  },
};
