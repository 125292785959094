import _ from 'lodash';
// eslint-disable-next-line import/no-cycle
import apiClient from '../../api';
import { isObj, isArr } from '../../utils';
// eslint-disable-next-line import/no-cycle
import store from '..';
import initialState from '../initialState';
import countriesOrder from '../../config/countriesOrder';

const usersApi = apiClient.merchy.users;

/**
 * @description Is valid
 * @param users
 * @returns {boolean}
 */
const isValid = (users) => {
  const {
    activeItem,
    items,
    itemsMeta,
    internalItems,
    internalItemsMeta,
    pendingItems,
    pendingItemsMeta,
    roles,
    countries,
    statuses,
    managers,
    notificationTypes,
    assignable,
    assignmentTypes,
    subRoles,
  } = users;

  const isValidActiveItem =
    isObj(activeItem) &&
    isObj(activeItem.settings) &&
    isArr(activeItem.notifications);

  return (
    isObj(users) &&
    isArr(items) &&
    isObj(itemsMeta) &&
    isArr(internalItems) &&
    isObj(internalItemsMeta) &&
    isArr(pendingItems) &&
    isObj(pendingItemsMeta) &&
    isArr(roles) &&
    isArr(countries) &&
    isArr(statuses) &&
    isArr(managers) &&
    isArr(notificationTypes) &&
    isArr(assignable) &&
    isArr(assignmentTypes) &&
    isArr(subRoles) &&
    isValidActiveItem
  );
};

/**
 * @description Init state
 * @param initialState
 * @returns {*}
 */
const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial users state');
  }

  const {
    activeItem,
    items,
    itemsMeta,
    internalItems,
    internalItemsMeta,
    pendingItems,
    pendingItemsMeta,
    roles,
    countries,
    statuses,
    managers,
    assignable,
    assignmentTypes,
    subRoles,
  } = initialState;

  return {
    activeItem,
    items,
    itemsMeta,
    internalItems,
    internalItemsMeta,
    pendingItems,
    pendingItemsMeta,
    roles,
    countries,
    statuses,
    managers,
    assignable,
    assignmentTypes,
    subRoles,
  };
};

/**
 * @description Getters
 * @type {*}
 */
export const getters = {
  activeItem: ({ activeItem }) => activeItem,
  items: ({ items }) => items,
  itemsMeta: ({ itemsMeta }) => itemsMeta,
  itemsData: ({ items, itemsMeta }) => ({ items, itemsMeta }),
  internalItems: ({ internalItems }) => internalItems,
  internalItemsMeta: ({ internalItemsMeta }) => internalItemsMeta,
  internalItemsData: ({ internalItems, internalItemsMeta }) => ({
    items: internalItems,
    meta: internalItemsMeta
  }),
  pendingItems: ({ pendingItems }) => pendingItems,
  pendingItemsMeta: ({ pendingItemsMeta }) => pendingItemsMeta,
  pendingItemsData: ({ pendingItems, pendingItemsMeta }) => ({
    items: pendingItems,
    meta: pendingItemsMeta,
  }),
  roles: (state) => state.roles,
  countries: ({ countries }) => countries,
  statuses: ({ statuses }) => statuses,
  managers: ({ managers }) => managers,
  notificationTypes: ({ notificationTypes }) => notificationTypes,
  assignable: ({ assignable }) => assignable,
  assignmentTypes: ({ assignmentTypes }) => assignmentTypes,
  subRoles: ({ subRoles }) => subRoles,
};

/**
 * @description Handle alerts
 * @param data
 * @param alertType
 */
const handleAlerts = (data, alertType = 'error') => (
  store.dispatch('alerts/set', {
    data,
    alertType
  })
);

const actions = {
  create: (context, user) => {
    return usersApi.createUser(user).then((res) => {
      const nextUser = res.data.data;
      const { firstname, lastname } = nextUser;

      handleAlerts({
        data: {
          message: `User with name "${firstname} ${lastname}" created successfully.`
        }
      }, 'success');

      return res;
    });
  },
  update: ({ commit, state }, user) => {
    return usersApi.updateUser(user).then((res) => {
      const { activeItem } = state;
      const nextUser = res.data.data;
      const { firstname, lastname } = nextUser;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          ...nextUser,
        },
      };

      commit('SET', nextState);

      handleAlerts({
        data: {
          message: `User with name "${firstname} ${lastname}" has been updated successfully.`
        }
      }, 'success');

      return res;
    })
  },
  updateUserOptions: (context, user) => (
    usersApi.updateUserOptions(user).then((res) => res.data.data)
  ),
  getItem: ({ commit, state }, query) => (
    usersApi.getItem(query).then((res) => {
      const { activeItem } = state;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          ...res.data.data
        }
      };

      return commit('SET', nextState);
    })
  ),
  getItems: ({ commit, state }, query) => (
    usersApi.getItems(query).then((res) => {
      const { data, meta } = res.data;

      const nextItems = data.map(item => ({
        ...item,
        name: `${item.firstname} ${item.lastname}`
      }));

      const nextState = {
        ...state,
        items: nextItems,
        itemsMeta: meta
      };

      commit('SET', nextState);

      return nextItems;
    })
  ),
  getUsersCount: () => {
    return usersApi.getUsersCount().then(res => {
      const data = res.data.data;
      const arr = [data.pending_users_count, data.active_users_count]
      return arr;
    }).catch(err => err);
  },
  getInternalItems: ({ commit, state }, query) => (
    usersApi.getInternalItems(query).then((res) => {
      const { data, meta } = res.data;
      const nextState = {
        ...state,
        internalItems: data,
        internalItemsMeta: meta
      };

      commit('SET', nextState)

      return data;
    })
  ),
  getPendingItems: ({ commit, state }, query) => (
    usersApi.getItems({ ...query, pending: 1 }).then((res) => {
      const { data, meta } = res.data;
      const nextState = {
        ...state,
        pendingItems: data,
        pendingItemsMeta: meta
      };

      return commit('SET', nextState);
    })
  ),
  getRoles: ({ commit, state }) => {
    return usersApi.getRoles().then((res) => {
      const nextRoles = _.sortBy(res.data.data, role => role.name.toLowerCase());
      const nextState = {
        ...state,
        roles: nextRoles
      };

      commit('SET', nextState);

      return nextRoles;
    })
  },
  // TODO merge with getRoles later
  getRolesSpecified: (context, query) => {
    return usersApi.getRoles(query)
      .then(res => res.data.data)
      .catch(err => err)
  },
  getUsersManagers: ({ commit, state }, query) => (
    usersApi.getUsersRolesByCountry(query).then(res => {
      const { activeItem } = state;
      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          managers: res.data.data
        }
      };

      return commit('SET', nextState);
    })
  ),
  getCountries: ({ commit, state }) => {
    return usersApi.getUserCountries().then((res) => {
      const nextCountries = res.data.data;

      nextCountries.sort(function(a, b) {
        return countriesOrder.indexOf(a.code) - countriesOrder.indexOf(b.code);
      });

      const nextState = {
        ...state,
        countries: nextCountries
      };

      commit('SET', nextState)
      return nextCountries;
    })
  },
  getStatuses: ({ commit, state }) => (
    usersApi.getUserStatuses().then((res) => {
      const nextStatuses = _.sortBy(res.data.data, status => status.name.toLowerCase());
      const nextState = {
        ...state,
        statuses: nextStatuses
      };

      return commit('SET', nextState);
    })
  ),
  setActiveItem: ({ commit, state }, user) => {
    const nextState = {
      ...state,
      activeItem: {
        ...state.activeItem,
        ...user
      }
    };

    return commit('SET', nextState);
  },
  getSettings: ({ commit, state }, query) => (
    usersApi.getSettings(query).then((res) => {
      const { activeItem } = state;
      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          settings: res.data.data
        }
      };

      return commit('SET', nextState);
    })
  ),
  updateSettings: ({ commit, state }, query) => (
    usersApi.updateSettings(query).then((res) => {
      const { activeItem } = state;
      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          ...res.data.data
        }
      };

      commit('SET', nextState);

      return handleAlerts({
        data: {
          message: 'User settings updated successfully.'
        }
      }, 'success');
    })
  ),
  updateStatus: (context, query) => (
    usersApi.updateUserStatus(query).then(() => {
      const { firstname, lastname } = query;

      return handleAlerts({
        data: {
          message: `User with name "${firstname} ${lastname}" has been updated successfully.`
        }
      }, 'success');
    })
  ),
  getNotifications: ({ commit, state }, query) => (
    usersApi.getNotifications(query).then((res) => {
      const { activeItem } = state;
      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          notifications: res.data.data
        }
      };

      return commit('SET', nextState);
    })
  ),
  addNotification: (context, query) => (
    usersApi.addNotification(query)
  ),
  removeNotification: (context, query) => (
    usersApi.removeNotification(query)
  ),
  getNotificationAccountTypes: ({ commit, state }) => (
    usersApi.getNotificationAccountTypes().then((res) => {
      const nextState = {
        ...state,
        notificationTypes: res.data.data
      };

      return commit('SET', nextState);
    })
  ),
  getAssignmentTypes: ({ commit, state }) => (
    usersApi.getAssignmentTypes().then((res) => {
      const nextState = {
        ...state,
        assignmentTypes: res.data.data
      };

      return commit('SET', nextState);
    })
  ),
  getManagers: ({ commit, state }, query) => (
    usersApi.getManagers(query).then((res) => {
      const nextManagers = res.data.data.map(item => ({
        ...item,
        name: `${item.firstname} ${item.lastname}`
      }));

      const nextState = {
        ...state,
        managers: nextManagers
      };

      commit('SET', nextState);

      return nextManagers;
    })
  ),
  getAssignableUsers: (context, query) => (
    usersApi.getAssignableUsers(query)
  ),
  getAssignees: ({ commit, state }, query) => (
    // TODO Merge this and the above method, right now they are separate cause of component expected format differense
    usersApi.getAssignableUsers(query).then((res) => {
      const nextAssignableUsers = res.data.data.map(item => ({
        ...item,
        name: `${item.firstname} ${item.lastname}`
      }));

      const nextState = {
        ...state,
        assignable: nextAssignableUsers
      };

      commit('SET', nextState);

      return nextAssignableUsers;
    })
  ),
  getSubRoles: ({ commit, state }, query) => (
    usersApi.getSubRoles(query).then((res) => {
      const nextSubRoles = res.data.data;
      const nextState = {
        ...state,
        subRoles: nextSubRoles
      };

      commit('SET', nextState);

      return nextSubRoles;
    })
  ),
  reset: ({ commit }) => (
    commit('SET', initialState.users)
  ),
  set: ({ commit }, users) => {
    commit('SET', users);
  }
};

const mutations = {
  SET(state, users) {
    /* eslint-disable no-param-reassign */
    state.activeItem = users.activeItem;
    state.items = users.items;
    state.itemsMeta = users.itemsMeta;
    state.internalItems = users.internalItems;
    state.internalItemsMeta = users.internalItemsMeta;
    state.pendingItems = users.pendingItems;
    state.pendingItemsMeta = users.pendingItemsMeta;
    state.roles = users.roles;
    state.countries = users.countries;
    state.statuses = users.statuses;
    state.managers = users.managers;
    state.notificationTypes = users.notificationTypes;
    state.assignable = users.assignable;
    state.assignmentTypes = users.assignmentTypes;
    state.subRoles = users.subRoles;
  }
};

export default initialState => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
