import VuexPersistence from 'vuex-persist';
import initialState from './initialState';

const { localStorage } = window;

export default new VuexPersistence({
  storage: localStorage,
  key: 'pepper_business',
  // reducer deletes fields from persisted state, the fields which are not listed in the reducer stay the same after the page is refreshed
  reducer: (state) => ({
    ...state,
    alerts: initialState.alerts,
    creatorProgram: {
      ...state.creatorProgram,
      businesses: initialState.creatorProgram.businesses,
    },
    popups: initialState.popups,
    threads: {
      ...initialState.threads,
      commentPermissions: state.threads.commentPermissions,
    },
    reports: initialState.reports,
    comments: initialState.comments,
    contracts: {
      ...initialState.contracts,
      statuses: state.contracts.statuses,
      types: state.contracts.types,
    },
    billing: initialState.billing,
    payments: {
      ...initialState.statuses,
      statuses: state.payments.statuses,
    },
    invoices: {
      ...initialState.statuses,
      statuses: state.payments.statuses,
    },
    labels: initialState.labels,
    businesses: {
      ...initialState.businesses,
      statuses: state.businesses.statuses,
      notificationTypes: state.businesses.notificationTypes,
    },
    users: {
      ...initialState.users,
      roles: state.users.roles,
      statuses: state.users.statuses,
      countries: state.users.countries,
    },
    validation: initialState.validation,
    pins: initialState.pins,
    packages: initialState.packages,
    schedules: initialState.schedules,
    siteMessages: {
      ...initialState.siteMessages,
      statuses: state.siteMessages.statuses,
    },
    grids: initialState.grids,
    adManager: initialState.adManager,
  }),
});
