/**
 * unregisterServiceWorker.js
 *
 * Description: This file removes a specific service worker that we had for a while.
 * We didn't use it properly, and it was blocking the refresh of new versions of the app.
 *
 * Created: January, 2025
 *
 * Usage: Call this script to unregister the old service worker and ensure the app
 * updates correctly with new versions.
 */

navigator.serviceWorker.getRegistrations()
  .then(registrations => {
    registrations.forEach(registration => {
      if (registration?.active?.scriptURL.includes('service-worker.js')) {
        console.log(`Unregistering service worker: ${registration.active.scriptURL}`);
        registration.unregister()
          .then(success => {
            if (success) {
              console.log('Service worker successfully unregistered.');
            } else {
              console.warn('Failed to unregister the service worker.');
            }
          })
          .catch(error => {
            console.warn('Error during service worker unregistration:', error);
          });
      }
    });
  })
