<template>
  <div data-component-group="popup-form" data-component="feed-form">
    <slot name="beforeForm" />

    <div class="feed-description">
      {{ $t('google_shopping_feed_description') }}
    </div>

    <form @:submit.prevent="onSubmit">
      <v-flex xs12>
        <input-custom
          v-model="link"
          prepend-inner-icon="link"
          :label="label"
          required
          hint="For example: https://www.yourshop.com/feed.csv"
          :persistent-hint="true"
          type="text"
          :readonly="isDisabled"
          :error-messages="linkErrors"
          @blur="$v.link.$touch()"
        />
      </v-flex>

      <div class="form-actions">
        <v-btn
          v-if="onCancel"
          depressed
          class="trigger cancel-uri"
          color="grey lighten-2"
          @click="cancel"
        >
          {{ cancelTriggerLabel }}
        </v-btn>

        <v-btn
          v-if="onSubmit && !isDisabled"
          color="primary"
          class="trigger"
          depressed
          :loading="isLoading"
          @click="submit"
        >
          {{ confirmTriggerLabel }}
        </v-btn>
      </div>
    </form>
  </div>
</template>

<script>
  import { validationMixin } from 'vuelidate';
  import { required, url } from 'vuelidate/lib/validators';
  import colors from 'vuetify/es5/util/colors';

  export default {
    mixins: [validationMixin],
    validations() {
      return {
        link: {
          required,
          url,
        },
      };
    },
    props: {
      cancelTriggerLabel: {
        type: String,
        default() {
          return this.$t('i_dont_have_the_link');
        },
      },
      confirmTriggerLabel: {
        type: String,
        default() {
          return this.$t('submit_link');
        },
      },
      label: {
        type: String,
        default() {
          return this.$t('google_shopping_feed_link');
        },
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      onCancel: {
        type: Function,
        default: undefined,
      },
    },
    data() {
      return {
        link: '',
        isSubmitted: false,
        isLoading: false,
        dismissOnCancel: true,
        colors,
      };
    },
    computed: {
      linkErrors() {
        const errors = [];
        if (!this.$v.link.$dirty) {
          return errors;
        }
        if (!this.$v.link.required) {
          errors.push(`${this.label} is required`);
        }
        if (!this.$v.link.url) {
          errors.push(`Must be a valid ${this.label}`);
        }
        return errors;
      },
    },
    methods: {
      cancel() {
        this.clear();
        this.onCancel(this.dismissOnCancel);
      },
      submit() {
        this.$v.$touch();

        if (!this.canSubmitRequest() || this.$v.$error || this.$v.pending) {
          return false;
        }

        this.addFeed();
      },
      clear() {
        this.$v.$reset();
        this.link = '';
        this.isSubmitted = false;
      },
      addFeed() {
        const { link } = this;

        const nextFeed = {
          id: 1,
          url: link,
        };

        this.isLoading = true;

        return this.$store
          .dispatch('popups/setPopupFormData', nextFeed)
          .then((res) => {
            this.isLoading = false;
            this.handleAlerts(this.$t('your_shopping_feed_has_been_submitted'));

            return res;
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
      },
      handleAlerts(message, alertType = 'success') {
        const alert = {
          data: {
            message,
          },
        };

        return this.$store.dispatch('alerts/set', {
          data: alert,
          alertType,
        });
      },
      canSubmitRequest() {
        const { link, isSubmitted } = this;

        return link !== '' && !isSubmitted;
      },
      onSubmit() {
      // DEV NOTE: This empty method is required for @submit.prevent clause
      },
    },
  };
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/theme';

[data-component-group='popup-form'][data-component='feed-form'] {
  padding: $spacing-xl;
  background-color: map-deep-get($colors, shades, 'white');

  .feed-description {
    font-size: $text-size-md;
    margin: 0 0 30px 0;
    color: map-deep-get($colors, info);
  }

  .fluid-layout {
    flex-direction: column;

    &.single-line {
      flex-direction: row;
    }
  }

// TODO replace with form-buttons
  .form-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .v-btn {
      margin: 0 $spacing-sm 0 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
