import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { apiUrl, maxItemsPerPage } from './index';

export default {
  getItems(options = {}) {
    const { countries, page = 1, perPage = maxItemsPerPage, type = '' } = options;

    const params = {
      page,
      countries
    };

    if (perPage > -1) {
      params.per_page = perPage;
    }

    if (type !== '') {
      params.type = type;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/schedule/pins`,
      params,
    });
  },
  getItem(options = {}) {
    const { id, country } = options;

    return axios({
      method: 'get',
      url: `${apiUrl}/schedule/pins/${country}/${id}`,
    });
  },
  createItem(options = {}) {
    const { country, ...restPin } = options;

    return axios({
      method: 'post',
      url: `${apiUrl}/schedule/pins/${country}`,
      data: restPin,
    });
  },
  updateItem(options = {}) {
    const { id, country, ...restPin } = options;

    return axios({
      method: 'put',
      url: `${apiUrl}/schedule/pins/${country}/${id}`,
      data: restPin,
    });
  },
  removeItem(options = {}) {
    const { id, country } = options;

    return axios({
      method: 'delete',
      url: `${apiUrl}/schedule/pins/${country}/${id}`,
    });
  },
  getPlatforms(options = {}) {
    const { country } = options;

    return axios({
      method: 'get',
      url: `${apiUrl}/schedule/pins/${country}/platforms`,
    });
  },
  getVisibilities(options = {}) {
    const { country } = options;

    return axios({
      method: 'get',
      url: `${apiUrl}/schedule/pins/${country}/visibilities`,
    });
  },
  getPages(options = {}) {
    const { country } = options;

    return axios({
      method: 'get',
      url: `${apiUrl}/schedule/pins/${country}/pages`,
    });
  },
  getPageObjects(options = {}) {
    const { country, page_id, search } = options;
    const params = {
      page_id,
      search,
    };

    return axios({
      method: 'get',
      url: `${apiUrl}/schedule/pins/${country}/page_objects`,
      params,
    });
  },
  getThreads(options = {}) {
    const {
      page = 1,
      perPage = maxItemsPerPage,
      thread_id = -1,
      start_date = '',
      end_date = '',
      countries = [],
    } = options;

    const params = {
      page,
      countries
    };

    if (perPage > -1) {
      params.per_page = perPage;
    }

    if (thread_id > -1) {
      params.thread_id = thread_id;
    }

    if (start_date !== '') {
      params.start_date = start_date;
    }

    if (end_date !== '') {
      params.end_date = end_date;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/schedule/threads`,
      params,
    });
  },
};
