import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { maxItemsPerPage, apiUrl } from './index';

export default {
  getItems(options = {}) {
    const {
      page = 1,
      perPage = maxItemsPerPage,
      order = 'asc',
      sort = '',
      type = -1,
      countries = [],
      status = -1,
      owner = -1,
      business = -1,
      expire_before = '',
      expire_soon = 0,
      domain = '',
    } = options;

    const params = {
      countries,
      page,
      order,
      sort,
    };

    if (perPage > -1) {
      params.per_page = perPage;
    }

    if (type > -1) {
      params.type = type;
    }

    if (status > -1) {
      params.status = status;
    }

    if (domain !== '') {
      params.domain = domain;
    }

    if (owner > -1) {
      params.owner = owner;
    }

    if (expire_before !== '') {
      params.expire_before = expire_before;
    }

    if (expire_soon > -1) {
      params.expire_soon = expire_soon;
    }

    if (business > -1) {
      params.business = business;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/contracts`,
      params,
    });
  },
  getDomains(options = {}) {
    const { search = '' } = options;

    const params = {};

    if (search.length > 2) {
      params.domainUrl = search;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/contracts/domains`,
      params,
    });
  },
  createItem(item) {
    return axios({
      method: 'post',
      url: `${apiUrl}/contracts`,
      data: {
        ...item,
      },
    });
  },
  updateItem(contract) {
    return axios({
      method: 'put',
      url: `${apiUrl}/contracts/${contract.id}`,
      data: {
        ...contract,
      },
    });
  },
  getRedirect({ id }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/contracts/billing/${id}`
    });
  },
  getItem({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/contracts/${id}`,
    });
  },
  getContractTypes() {
    return axios({
      method: 'get',
      url: `${apiUrl}/contracts/types`,
    });
  },
  getStatuses() {
    return axios({
      method: 'get',
      url: `${apiUrl}/contracts/statuses`,
    });
  },
  getPaymentTypes() {
    return axios({
      method: 'get',
      url: `${apiUrl}/contracts/payment_types`,
    });
  },
  setBilling(query) {
    return axios({
      method: 'post',
      url: `${apiUrl}/contracts/${query.id}/billing`,
      data: {
        ...query,
      },
    });
  },
  setDomain(query) {
    return axios({
      method: 'post',
      url: `${apiUrl}/contracts/${query.id}/domain`,
      data: {
        ...query,
      },
    });
  },
  addCapAmount(query) {
    return axios({
      method: 'post',
      url: `${apiUrl}/contracts/${query.id}/capping-discount`,
      data: {
        ...query,
      },
    });
  },
  removeCapAmount(id) {
    return axios({
      method: 'delete',
      url: `${apiUrl}/contracts/${id}/capping-discount`,
    });
  },
  getPrices({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/contracts/${id}/price`,
    });
  },
  addPrice(query) {
    return axios({
      method: 'post',
      url: `${apiUrl}/contracts/${query.id}/price`,
      data: {
        ...query,
      },
    });
  },
  removePrice(item) {
    return axios({
      method: 'delete',
      url: `${apiUrl}/contracts/${item.id}/price/${item.price_id}`,
    });
  },
};
