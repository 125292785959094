/* eslint-disable */

/**
 * @responsibility
 * forwards track actions to the appropriate track services and allows to intercept track actions
 */
import { trackOcular } from './ocular';
import { TrackService } from './payload';
import { findTrackContextElem, getTrackContext, getTrackContextPath, validateContext } from './context';
import { Interceptor } from '@/utils/interceptor';

const interceptor = new Interceptor();
/**
 * allows to filter, transform or merge track actions via given interceptor
 */
export function addInterceptor(interceptorCb) {
  interceptor.add(interceptorCb);
}
export async function track({ type, target, context = [], interaction = true, services = [TrackService.Ocular], simulate = false, }) {
  if (target instanceof Element) {
    const targetElem = findTrackContextElem(target);
    target = getTrackContext(targetElem);
    context = getTrackContextPath(targetElem.parentElement);
  } else if (context instanceof Element) {
    context = getTrackContextPath(context);
  }
  validateContext(target);

  if (target?.data?.isDisabled === true) {
    return;
  }

  const trackAction = await interceptor.intercept({ type, target, context, interaction, services, simulate });
  if (simulate)
    return trackAction; // bookmarklet or tests

  if (!trackAction)
    return; // intercepted

  trackOcular(trackAction);
}
