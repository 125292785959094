import Vue from 'vue';
import Router from 'vue-router';
import multiguard from 'vue-router-multiguard';
// eslint-disable-next-line import/no-cycle
import {
  redirectUnauthenticated,
  redirectAuthenticated,
  redirectUnauthorized,
  redirectFromIndex,
} from './traps';

// routes
import siteMessages from './routes/siteMessages';
import billing from './routes/billing';
import businessUsers from './routes/businessUsers';
import businesses from './routes/businesses';
import cannedReplies from './routes/cannedReplies';
import contracts from './routes/contracts';
import creatorProgram from './routes/creatorProgram';
import events from './routes/events';
import feeds from './routes/feeds';
import grooveWidget from './routes/grooveWidget';
import home from './routes/home';
import internalUsers from './routes/internalUsers';
import invoices from './routes/invoices';
import labels from './routes/labels';
import login from './routes/login';
import logout from './routes/logout';
import networkReview from './routes/networkReview';
import notFound from './routes/notFound';
import packages from './routes/packages';
import password from './routes/password';
import payments from './routes/payments';
import pins from './routes/pins';
import promotions from './routes/promotions';
import register from './routes/register';
import reports from './routes/reports';
import scheduledThreads from './routes/scheduledThreads';
import sponsorships from './routes/sponsorships';
import threads from './routes/threads';
import users from './routes/users';
import verifyLink from './routes/verifyLink';

if (process.env.NODE_ENV !== 'test') {
  Vue.use(Router);
}

const router = new Router({
  mode: 'history',
  base: '/',
  saveScrollPosition: true,
  routes: [
    ...billing,
    ...businesses,
    ...businessUsers,
    ...cannedReplies,
    ...contracts,
    ...creatorProgram,
    ...events,
    ...feeds,
    ...grooveWidget,
    ...home,
    ...internalUsers,
    ...invoices,
    ...labels,
    ...login,
    ...logout,
    ...networkReview,
    ...notFound,
    ...packages,
    ...password,
    ...payments,
    ...pins,
    ...promotions,
    ...register,
    ...reports,
    ...scheduledThreads,
    ...siteMessages,
    ...sponsorships,
    ...threads,
    ...users,
    ...verifyLink,
  ],
});

// configure traps
router.beforeEach(
  multiguard([
    redirectUnauthenticated,
    redirectAuthenticated,
    redirectUnauthorized,
    redirectFromIndex,
  ]),
);

export default router;
